import { Box, Tooltip } from '@mui/material';
import { useMemo } from 'react';

import { Button } from '@/components';
import { DownloadIcon } from '@/icons';
import { OrganizationMember } from '@/store/member-management-api';

import { rowsToInlineCsv } from './utils';

export type CsvDownloadProps = {
  data: OrganizationMember[] | undefined;
};

export const CsvDownload = ({ data }: CsvDownloadProps) => {
  const csvData = useMemo(() => {
    const rowData = [
      [
        'Name',
        'Member Email',
        'Login Email',
        'Access',
        'Last Listened (UTC)',
        'Date Added (UTC)',
      ],
      ...(data?.map(c => [
        c.name ? `"${c.name.replaceAll('"', '""')}"` : '',
        c.organization_email ?? '',
        c.user_email ?? '',
        c.state ?? '',
        c.last_user_listening ?? '',
        c.organization_member_created_at ?? '',
      ]) ?? []),
    ];

    return rowsToInlineCsv(rowData);
  }, [data]);

  return (
    <Box>
      <Tooltip title="Download CSV with all Members">
        {/** span needed for tooltip to attach to */}
        <span>
          <Button
            label="Download CSV"
            component="a"
            startIcon={<DownloadIcon />}
            disabled={!data?.length}
            href={csvData}
            download="lumiq-members.csv"
          />
        </span>
      </Tooltip>
    </Box>
  );
};
