export const timeSince = (
  stringDate: string | number | Date | null,
  smallestPossibleUnit = 'minute',
) => {
  if (stringDate === null) {
    return '-';
  }
  const date = new Date(stringDate);

  // If the date is invalid, return '-'
  if (isNaN(date.getTime())) {
    return '-';
  }

  const now = new Date();
  const diffMs = now.getTime() - date.getTime();

  // Calculate different time units
  const years = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 365.25));
  const months = Math.floor(
    (diffMs % (1000 * 60 * 60 * 24 * 365.25)) / (1000 * 60 * 60 * 24 * 30.44),
  );
  const days = Math.floor(
    (diffMs % (1000 * 60 * 60 * 24 * 30.44)) / (1000 * 60 * 60 * 24),
  );
  const hours = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

  if (years > 0) {
    if (years >= 1 && years < 2) {
      return 'last year';
    }
    return `${years} year${years !== 1 ? 's' : ''}`;
  }
  if (smallestPossibleUnit === 'year') {
    return 'this year';
  }

  if (months > 0) {
    if (months >= 1 && months < 2) {
      return 'last month';
    }
    return `${months} month${months !== 1 ? 's' : ''}`;
  }
  if (smallestPossibleUnit === 'month') {
    return 'this month';
  }

  if (days > 0) {
    if (days >= 1 && days < 2) {
      return 'yesterday';
    }
    return `${days} day${days !== 1 ? 's' : ''}`;
  }
  if (smallestPossibleUnit === 'day') {
    return 'today';
  }

  if (hours > 0) {
    return `${hours} hour${hours !== 1 ? 's' : ''}`;
  }
  if (smallestPossibleUnit === 'hour') {
    return 'Just now';
  }

  if (minutes > 0) {
    return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
  }

  return 'Just now';
};

export const utcToLocalDate = (utcDate: string) => {
  return new Date(utcDate).toLocaleDateString();
};

export const utcToLocalDateTime = (utcDate: string) => {
  return new Date(utcDate).toLocaleString();
};

export const mapMemberStateToUiLabel = (
  state: 'active' | 'invited' | 'archived',
) => {
  switch (state) {
    case 'active':
      return 'Enrolled';
    case 'invited':
      return 'Invited';
    case 'archived':
      return 'Removed';
    default:
      // unknown runtime state
      return state;
  }
};

export const rowsToInlineCsv = (rows: (string | number | boolean)[][]) => {
  let csvContent = '';

  rows.forEach(rowArray => {
    let row = rowArray.join(',');
    csvContent += row + '\r\n';
  });

  const universalBOM = '\uFEFF';
  return `data:text/csv; charset=utf-8,${encodeURIComponent(universalBOM + csvContent)}`;
};
